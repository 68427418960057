"use client";
import { SxProps } from "@mui/material";
import Fab, { FabProps } from "@mui/material/Fab";
import React from "react";
import { Link as LinkIcon } from "@mui/icons-material";

const linkFabStyles: SxProps = {
    position: "absolute",
    top: "8px",
    right: "8px",
    borderRadius: "12px",
    backgroundColor: "tertiary.containerDim",
};

const linkIconStyles: SxProps = {
    color: "tertiary.onContainerDim",
};

const QuoteLinkFab = ({ link, ...props }: FabProps & { link: string }) => {
    const redirect = () => {
        window.open(link, "_blank", "noopener noreferrer");
    };

    return (
        <Fab {...props} sx={linkFabStyles} size="small" onClick={redirect}>
            <LinkIcon sx={linkIconStyles} />
        </Fab>
    );
};

export default QuoteLinkFab;
