"use client";
import React from "react";
import { styled } from "@mui/material/styles";
import StepConnector from "@mui/material/StepConnector";
import stepConnectorClasses from "@mui/material/StepConnector/stepConnectorClasses";
import Grid from "@mui/material/Unstable_Grid2";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { StepIconProps } from "@mui/material/StepIcon/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import {
    ListAlt,
    PsychologyOutlined,
    SettingsEthernet,
} from "@mui/icons-material";
import dynamic from "next/dynamic";

const ConnectPane = dynamic(() => import("./ConnectPane"));
const LearnPane = dynamic(() => import("./LearnPane"));
const TrainPane = dynamic(() => import("./TrainPane"));

const LandingConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        transition: "0.2s",
        [theme.breakpoints.down("md")]: {
            top: 21,
        },
        [theme.breakpoints.only("md")]: {
            top: 29,
        },
        [theme.breakpoints.up("lg")]: {
            top: 37,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 6,
        borderRadius: 3,
        border: 0,
        background: theme.palette.secondary.containerDim,
        marginLeft: "15%",
        marginRight: "15%",
    },
}));

const LandingStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.secondary.containerDim,
    zIndex: 1,
    color: ownerState.active
        ? theme.palette.common.white
        : theme.palette.secondary.onContainerDim,
    [theme.breakpoints.down("md")]: {
        width: 48,
        height: 48,
    },
    [theme.breakpoints.only("md")]: {
        width: 64,
        height: 64,
    },
    [theme.breakpoints.up("lg")]: {
        width: 80,
        height: 80,
    },
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "0.2s",
    "&:hover": {
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    ".MuiSvgIcon-root": {
        transition: "0s",
        [theme.breakpoints.down("md")]: {
            width: 32,
            height: 32,
        },
        [theme.breakpoints.only("md")]: {
            width: 40,
            height: 40,
        },
        [theme.breakpoints.up("lg")]: {
            width: 48,
            height: 48,
        },
    },
    ...(ownerState.active && {
        backgroundImage: `linear-gradient(137deg, ${theme.palette.secondary.base} 10.9%, ${theme.palette.tertiary.base} 91.59%)`,
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
    ".MuiStepLabel-label": {
        color: theme.palette.secondary.onContainerDim,
        // fontWeight: "bold",
        "&.Mui-active": {
            color: theme.palette.secondary.onContainerDim,
        },
    },
}));

function LandingStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <SettingsEthernet />,
        2: <PsychologyOutlined />,
        3: <ListAlt />,
    };

    return (
        <LandingStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </LandingStepIconRoot>
    );
}

const LandingStepper = () => {
    const [activeStep, setActiveStep] = React.useState(0);

    const steps: string[] = ["CONNECT", "LEARN", "TRAIN"];

    return (
        <Grid container spacing={0} marginTop="40px">
            <Grid xs={12}>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<LandingConnector />}
                >
                    {steps.map((label, index) => (
                        <Step key={label} onClick={() => setActiveStep(index)}>
                            <StyledStepLabel
                                StepIconComponent={LandingStepIcon}
                            >
                                {label}
                            </StyledStepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid xs={12}>
                {activeStep === 0 ? (
                    <ConnectPane />
                ) : activeStep === 1 ? (
                    <LearnPane />
                ) : (
                    <TrainPane />
                )}
            </Grid>
        </Grid>
    );
};

export default LandingStepper;
