"use client";
import React from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { navbarHeight } from "../helpers/defaultValues";

const GradientCardStyles =
    (navbarHeight: number, position: "top" | "side"): SxProps =>
    (theme: Theme) => ({
        background: `linear-gradient(109deg, ${theme.palette.primary.base} 10.55%, ${theme.palette.secondary.onContainerDim} 105.26%)`,
        paddingLeft: "5%",
        paddingRight: "5%",
        paddingBottom: "24px",
        paddingTop: position === "top" ? `${navbarHeight + 16}px` : "16px",
        boxShadow: theme.shadows[3],
    });

const GradientCard = ({ children }: { children?: React.ReactNode }) => {
    const sx = GradientCardStyles(navbarHeight, "top");
    return <Card sx={sx}>{children}</Card>;
};

export default GradientCard;
